<template>
  <validation-observer ref="form" v-slot="{ handleSubmit, failed }">
    <v-form lazy-validation @submit.prevent="handleSubmit(saveVehicle)">
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" hidden md="6" sm="12">
              <v-text-field v-model="editedVehicleItem.id" :label="$t('vehiclesPage.id')"></v-text-field>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="12">
              <validation-provider rules="required|max:200" v-slot="{ errors }" name="plate_number">
                <v-text-field
                  v-model="editedVehicleItem.plate_number"
                  autofocus
                  clearable
                  counter="200"
                  :label="$t('vehiclesPage.vehiclePlates')"
                  :error-messages="errors"></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="12">
              <validation-provider rules="required|max:200" v-slot="{ errors }" name="brand">
                <v-text-field
                  v-model="editedVehicleItem.brand"
                  clearable
                  counter="200"
                  :label="$t('vehiclesPage.vehicleBrand')"
                  :error-messages="errors"></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="12">
              <validation-provider rules="required" v-slot="{ errors }" name="vehicle_class">
                <v-autocomplete
                  v-model="editedVehicleItem.vehicle_class_id"
                  :items="filteredVehicleClasses"
                  clearable
                  item-text="name"
                  item-value="id"
                  :label="$t('vehiclesPage.vehicleClass')"
                  :error-messages="errors"
                  :no-data-text="$t('select.noDataAvailable')"
                  @focus="$event.target.click()"></v-autocomplete>
              </validation-provider>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="12">
              <validation-provider rules="max:200" v-slot="{ errors }" name="licence">
                <v-text-field
                  v-model="editedVehicleItem.licence"
                  clearable
                  counter="200"
                  :label="$t('vehiclesPage.licence')"
                  :error-messages="errors"></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="6" lg="4" md="4" sm="12">
              <validation-provider rules="required|numeric|min_value:1" v-slot="{ errors }" name="number_of_passengers">
                <v-text-field
                  v-model="editedVehicleItem.number_of_passengers"
                  clearable
                  :label="$t('vehiclesPage.passengersNumber')"
                  min="1"
                  step="1"
                  type="text"
                  :error-messages="errors"></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="6" lg="4" md="4" sm="12">
              <validation-provider rules="numeric_decimal|min_value:0" v-slot="{ errors }" name="fuel_consumption">
                <v-text-field
                  v-model="editedVehicleItem.fuel_consumption"
                  clearable
                  :label="
                    user.organisation?.organisation_settings?.distance_unit == 'mi'
                      ? $t('vehiclesPage.fuelConsumptionMi')
                      : $t('vehiclesPage.fuelConsumption')
                  "
                  min="0"
                  step="0.1"
                  type="text"
                  :error-messages="errors"></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="6" lg="4" md="4" sm="12">
              <validation-provider
                :rules="editedVehicleItem.year_of_manufacture ? 'numeric|min_value:0|min:4|max:4' : 'numeric_decimal'"
                v-slot="{ errors }"
                name="year_of_manufacture">
                <v-text-field
                  v-model="editedVehicleItem.year_of_manufacture"
                  clearable
                  :label="$t('vehiclesPage.manufactureYear')"
                  min="0"
                  step="1"
                  type="text"
                  :error-messages="errors"></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="12">
              <validation-provider
                rules="numeric_decimal|min_value:0"
                v-slot="{ errors }"
                :name="
                  user.organisation?.organisation_settings?.distance_unit == 'mi' ? 'price_per_mi' : 'price_per_mi'
                ">
                <v-text-field
                  v-model="editedVehicleItem.price_per_km"
                  clearable
                  :label="
                    user.organisation?.organisation_settings?.distance_unit == 'mi'
                      ? $t('vehiclesPage.priceMi')
                      : $t('vehiclesPage.price')
                  "
                  min="0"
                  step="0.01"
                  type="text"
                  :error-messages="errors"></v-text-field>
              </validation-provider>
            </v-col>

            <v-col cols="12" lg="4" md="4" sm="12">
              <validation-provider rules="numeric_decimal|min_value:0" v-slot="{ errors }" name="price_per_hour">
                <v-text-field
                  v-model="editedVehicleItem.price_per_hour"
                  clearable
                  :label="$t('vehiclesPage.hourPrice')"
                  min="0"
                  step="0.01"
                  type="text"
                  :error-messages="errors"></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="12">
              <validation-provider
                rules="numeric_decimal|min_value:0"
                v-slot="{ errors }"
                name="price_per_waiting_hour">
                <v-text-field
                  v-model="editedVehicleItem.price_per_waiting_hour"
                  clearable
                  :label="$t('vehiclesPage.hourWaitingPrice')"
                  min="0"
                  step="0.01"
                  type="text"
                  :error-messages="errors"></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="4" md="4" sm="4">
              <validation-provider rules="image|size:4000" v-slot="{ errors }" name="image">
                <v-file-input
                  v-model="editedVehicleItem.image"
                  name="image"
                  accept="image/jpg, image/jpeg, image/png"
                  counter
                  :label="$t('vehiclesPage.image')"
                  prepend-icon="mdi-camera"
                  show-size
                  small-chips
                  :error-messages="errors">
                </v-file-input>
              </validation-provider>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <button-submit :failed="failed" :loading="loading" buttonText="buttons.save"></button-submit>
      </v-card-actions>
    </v-form>
  </validation-observer>
</template>

<script>
import ButtonSubmit from '@/components/buttons/ButtonSubmit.vue';
import i18n from '@/i18n/i18n';

export default {
  name: 'VehicleForm',
  components: { ButtonSubmit },
  props: ['editedVehicleItem', 'vehicleClasses', 'user'],
  data: () => ({
    loading: false,
    filteredVehicleClasses: [],
  }),
  created() {
    this.filteredVehicleClasses = this.vehicleClasses?.map((item) => {
      if (item.active == '1' || item?.id === this.editedVehicleItem?.vehicle_class_id) {
        return item;
      }
    });
  },
  methods: {
    createFormData() {
      let formData = new FormData();
      if (this.editedVehicleItem.image) {
        formData.append('image', this.editedVehicleItem.image);
      }

      if (this.editedVehicleItem.id) {
        formData.append('_method', 'POST');
        formData.append('id', this.editedVehicleItem.id);
      } else {
        formData.append('id', '');
      }

      formData.append(
        'price_per_hour',
        this.editedVehicleItem.price_per_hour ? Number(this.editedVehicleItem.price_per_hour).toFixed(2) : ''
      );
      formData.append(
        'price_per_km',
        this.editedVehicleItem.price_per_km ? Number(this.editedVehicleItem.price_per_km).toFixed(2) : ''
      );
      formData.append(
        'price_per_waiting_hour',
        this.editedVehicleItem.price_per_waiting_hour
          ? Number(this.editedVehicleItem.price_per_waiting_hour).toFixed(2)
          : ''
      );
      formData.append('plate_number', this.editedVehicleItem.plate_number);
      formData.append('licence', this.editedVehicleItem.licence ? this.editedVehicleItem.licence : '');
      formData.append('brand', this.editedVehicleItem.brand ? this.editedVehicleItem.brand : '');
      formData.append('vehicle_class_id', this.editedVehicleItem.vehicle_class_id);
      formData.append('number_of_passengers', this.editedVehicleItem.number_of_passengers);
      formData.append(
        'fuel_consumption',
        this.editedVehicleItem.fuel_consumption ? Number(this.editedVehicleItem.fuel_consumption).toFixed(2) : ''
      );
      formData.append(
        'year_of_manufacture',
        this.editedVehicleItem.year_of_manufacture ? this.editedVehicleItem.year_of_manufacture : ''
      );
      return formData;
    },

    async saveVehicle() {
      this.loading = true;
      let path = 'saveVehicle';

      if (this.editedVehicleItem.id) {
        Object.assign(this.editedVehicleItem, { _method: 'POST' });
        path = 'updateVehicle';
      }

      let formData = this.createFormData();

      await this.$store
        .dispatch('vehicles/' + path, formData)
        .then(() => {
          this.$emit('loadAllVehicles');
          this.$emit('closeVehicleEdit');
          this.$emit('savedVehicleSuccessfully');
        })
        .catch((error) => {
          if (error.response?.status === 422) {
            this.$store
              .dispatch('errorMessages/errorMapper', error.response.data.message)
              .then((e) => this.$emit('errorOccurred', e));
          } else {
            this.$emit('errorOccurred', i18n.t('snackbar.anErrorOccured'));
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    resetForm() {
      this.$refs.form.reset();
    },
  },
};
</script>
